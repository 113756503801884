import React, {useState, useEffect} from "react";
import {Table, Button, tbody, th, thead, tr, Col, Alert} from "reactstrap";
import axios from "axios";
import {useUser} from "../context/user";
import moment from "moment";

function GiftCards(props) {
    const {dabblUser } = useUser();
    const loadAllFailed = props.allFailed;
    const [data, setData] = useState(null);
    const [requestError, setRequestError] = useState('');


    function requestGiftCardOrders(){
        if (loadAllFailed) {
            axios.get(`/user/giftcardorders/failed`)
                .then(result => {
                    if (result && result.status === 200 && !result.data.error) {
                        setData(result.data);
                    }
                }).catch(e => {
                setRequestError(e.message)
                console.error(e.message)
            });
            return;
        }
        if (!dabblUser || !dabblUser.id) { return }
        axios.get(`/user/${dabblUser.id}/giftcardorders`)
            .then(result => {
                if (result && result.status === 200 && !result.data.error) {
                    setData(result.data);
                }
            }).catch(e => {
            setRequestError(e.message)
            console.error(e.message)
        });
    }

    function getStatus(card) {
        if(card.isfulfilled && !card.isfailed) {
            return 'Processed'
        } else if (!card.isfulfilled && card.isfailed) {
            return 'Failed'
        } else if(!card.isfulfilled && !card.isfailed) {
            return 'Processing'
        } else {
            return 'Unknown'
        }
    }

    useEffect(() => {
        if (!dabblUser && !props.allFailed) { return }
        requestGiftCardOrders();
    }, [dabblUser, props.allFailed]);

    function reprocessCard(e, card) {
        e.preventDefault();
        setRequestError(null)
        axios.post(`/user/${card.userid}/reprocessorder`, {id: card.id})
            .then(result => {
                if (result && result.status === 200) {
                    // requestGiftCardOrders()
                    setData(data.map(c => (c.id === card.id ? {...c, isfailed: false} : c)))
                }
            }).catch(e => {
            setRequestError(e.message)
            console.error(e.message)
        });
    }
    return <Table size="sm" borderless hover responsive>
        <thead>
        <tr>
            <th>Date</th>
            <th>SKU</th>
            <th>Amount</th>
            <th>FName</th>
            <th>LName</th>
            <th>Email</th>
            {/*<th>Uri</th>*/}
            <th>Status</th>
        </tr>
        </thead>
        <tbody>{requestError && <Col><Alert color="danger">{requestError}</Alert></Col>}
        {data === null
            ? <tr><td>Loading...</td></tr>
            : data.map((row, index) => {
                return <tr key={index}>
                    <th scope="row">{moment.utc(row.createddate).format('YYYY-MM-DD HH:mm')}</th>
                    <td>{row.sku}</td>
                    <td>{row.amount}</td>
                    <td>{row.firstname}</td>
                    <td>{row.lastname}</td>
                    <td>{row.email}</td>
                    {/*<td>{row.uri}</td>*/}
                    <td>{getStatus(row)}</td>
                    <td>{getStatus(row) === 'Failed' ? <Button color='primary' onClick={(e)=>reprocessCard(e, row)}>Reprocess</Button> : <span/>} </td>
                </tr>
            })}
        </tbody>
    </Table>
}

export default GiftCards;
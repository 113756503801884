import React from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";
import GiftCards from "../components/GiftCards";

function GiftCardPage(props) {
    console.log("GiftCard PAGE")
    console.log(props)

    return  <Container>
        <Row>
            <Col sm={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }}>
                <Card>
                    <CardBody>
                        <GiftCards allFailed />
                        <Row>
                            <Col>
                                <Button color="primary" className='float-right' onClick={()=>props.history.goBack()}>Back</Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
}


export default GiftCardPage;
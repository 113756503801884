import React, { useState, useEffect }  from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './bootstrap-override.css';
import UserLookup from './pages/UserLookup';
import Login from './pages/Login';
import PrivateRoute from './PrivateRoute';
import axios from 'axios';
import { AuthContext } from "./context/auth";
import { UserContext } from "./context/user";
import { IvtContext } from "./context/ivt";
import {Button, Nav, NavItem} from 'reactstrap'
import {API_URL, APP_VERSION, USER_KEY} from './config'
import logoImage from "./assets/dabbl-logo-orange.png";
import UserDetails from "./pages/UserDetails";
import UserCharts from "./pages/UserCharts";
import IvtPage from "./pages/IvtPage";
import GiftCardPage from "./pages/giftCardsPage";

axios.defaults.baseURL = API_URL;

function App(props) {
    const [authUser, setAuthUser] = useState(null);
    const [dabblUser, setDabblUser] = useState(null);
    // const [ivt, setIvt] = useState(null);

    const setUser = (data) => {
        // console.log(data);
        if (data === null) {
            localStorage.removeItem(USER_KEY);
            axios.defaults.headers.common['Authorization'] = null;

            console.log("Authorization cleared")
        } else{
            localStorage.setItem(USER_KEY, JSON.stringify(data));
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
            console.log("Authorization token set")
        }
        setAuthUser(data);
    };
    if (!authUser && localStorage.getItem(USER_KEY) != null) {
        setUser(JSON.parse(localStorage.getItem(USER_KEY)))
    }
    useEffect(() => {
        // console.log(API_URL);
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response && 401 === error.response.status) {
                setUser(null);
                setDabblUser(null);
                // setIvt(null);
            } else {
                return Promise.reject(error.response ? error.response : error);
            }
        });
    });

    return (
        <AuthContext.Provider value={{ authUser, setAuthUser: setUser }}>
            <Router>
                <div>
                    {authUser &&
                    <Nav>
                        <NavItem>
                            <img top="true" style={{height: '30px', margin: '5px', display: 'block'}} src={logoImage} alt="Logo" />
                        </NavItem>
                        <NavItem>
                            <Link active="true" to="/userlookup" className='nav-link'>User Lookup</Link>
                        </NavItem>
                        <NavItem>
                            <Link active="true" to="/ivtPage" className='nav-link'>IVT</Link>
                        </NavItem>
                        <NavItem>
                            <Link active="true" to="/giftcards" className='nav-link'>Failed Gift Cards</Link>
                        </NavItem>

                        {/*<NavItem>*/}
                        {/*    <Link to="/refer" className='nav-link'>Link Referred Used</Link>*/}
                        {/*</NavItem>*/}
                        <NavItem className='ml-auto'>
                            <span>{`Signed In as ${authUser.username}`}</span>
                            <Button color="link" onClick={(e) => setUser(null)}>Sign Out</Button>
                        </NavItem>
                    </Nav>
                    }
                    <hr />
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        {/*<PrivateRoute path="/refer" exact component={Refer} />*/}

                        {/*<IvtContext.Provider value={{ ivt, setIvt }}>*/}
                        <UserContext.Provider value={{ dabblUser, setDabblUser }}>
                            <PrivateRoute path="/" exact component={UserLookup} />
                            <PrivateRoute path="/userlookup" exact component={UserLookup} />
                            <PrivateRoute path="/userdetails" exact component={UserDetails} />
                            <PrivateRoute path="/usercharts" exact component={UserCharts} />
                            <PrivateRoute path="/ivtPage" exact component={IvtPage} />
                            <PrivateRoute path="/giftcards" exact component={GiftCardPage} />
                            </UserContext.Provider>
                        {/*</IvtContext.Provider>*/}
                        <Redirect to='/' />
                    </Switch>
                    <div className="fixed-bottom font-italic font-weight-light">Build: {APP_VERSION}</div>
                </div>
            </Router>
        </AuthContext.Provider>
    );
}

export default App;
